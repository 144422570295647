<template>
  <footer class="player-footer flex-row flex-middle flex-space">
  <!-- player controls 
    <section class="player-links text-nowrap">
    <animation-selection></animation-selection>
    <a v-if=config.twitter.username class="common-btn text-faded" rel="noreferrer" :href="'https://twitter.com/'+config.twitter.username" title="Twitter"
       target="_blank">
      <i class="fab fa-twitter"></i>
    </a> &nbsp;
    <a v-if=config.facebook.page_id class="common-btn text-faded" rel="noreferrer" :href="'https://fb.me/'+config.facebook.page_id"
       title="Facebook"
       target="_blank">
      <i class="fab fa-facebook"></i>
    </a>
  </section>-->

  <!--
      <section class="player-links flex-row flex-left dedicaces" v-if="!mobile">
<i v-if="dedicaces.length && dedicace"class="fa-regular fa-comments fx fx-drop-in pad-right"></i>
    <transition-group name="transition" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
        <div v-if="dedicaces.length && dedicace" :key="dedicaces[currentDedicaceIndex].id" class="pad-left">
            <span>{{ dedicaces[currentDedicaceIndex].posteur }} - {{ dedicaces[currentDedicaceIndex].dedicace }}</span>
        </div>
    </transition-group>
         <i v-if="notification.code == '0'"class="fa-solid fa-exclamation fx fx-drop-in pad-right"></i>
         <i v-if="notification.code == '1'"class="fa-solid fa-check fx fx-drop-in pad-right"></i>
        <div v-if="notification.message" class="pad-left">
            <span v-if="notification.code == '0'" style="color:red">{{ notification.message }}</span>
            <span v-if="notification.code == '1'" style="color:green">{{ notification.message }}</span>
        </div>
  </section>
-->

<section class="player-links flex-row flex-left player-content">
      <div style="display:flex">
        <div id="album">
		<div id="cover" v-bind:style="{ 'background-image' : `url(  ${ this.currentSong.pochette } )` }">
			<div id="print">
			</div>
		</div>
		<div id="vinyl">
			<div id="print">
			</div>
		</div>
	</div>

        <div class="current-song" style="margin-top: 35px">
          <h3 v-if="this.currentSong.title" style="color: #fff;text-transform: capitalize;font-size: none;">{{ this.currentSong.title }}</h3>
          <h3 v-else style="color: #fff;text-transform: capitalize;">{{ config.title }}</h3>
          <h5 v-if="this.currentSong.artist" style="color: #fff;text-transform: capitalize;font-size: none;">{{ this.currentSong.artist }}</h5>
          <h5 v-else class="text-faded" style="color: #fff;text-transform: capitalize;">{{ config.title }}</h5>
        </div>

      </div>
  </section>

  <!-- player controls -->
  <section class="player-controls" style="position:fixed;left: 60px;right: 60px;margin: auto;display:flex;justify-content: center;width: max-content;">
    <button class="common-btn" @click="togglePlay()" aria-label="play">
      <i v-if="loading" class="fas fa-circle-notch fx fx-spin-right" key="wait"></i>
      <i v-else-if="playing" class="fa-solid fa-circle-stop fx fx-drop-in" key="stop"></i>
      <i v-else class="fa-solid fa-circle-play fx fx-drop-in" key="play"></i>
    </button>
    <div class="form-slider push-left">
      <i class="fa fa-volume-down" @click="volumeDown()"></i>
      <input aria-label="volume" class="common-slider" type="range" min="0.0" max="1.0" step="0.1" value="0.5"
             v-model="volume"/>
      <i class="fa fa-volume-up" @click="volumeUp()"></i>
    </div>
 <!--   <div class="text-clip push-left">
      <span>{{ timeDisplay }}</span>
      <span class="text-faded" v-if="hasChannel">&nbsp;|&nbsp;</span>
     <span class="fx fx-fade-in fx-delay-1" v-if="station"
            :key="stationId">{{ station.title }}</span>
    </div>-->
  </section>

    <section class="player-links flex-row flex-right">
    <button class="common-btn" rel="noreferrer" :href="'https://fb.me/'+config.facebook.page_id">
      <i class="fa-brands fa-youtube"></i>
    </button>
    <button class="push-left common-btn" rel="noreferrer" @click="submitTopVote('plus')" type="button">
      <i class="fa-solid fa-heart"></i>
    </button>
    <button class="push-left common-btn" rel="noreferrer" @click="submitTopVote('moins')" type="button">
      <i class="fa-solid fa-heart-crack"></i>
    </button>
    </section>
  
  </footer>
</template>

<script>
import axios from 'axios';
import animationSelection from '@/views/components/animationSelection'
import _audio from '../../../js/audio';
import { mapState  } from 'vuex';
export default {
  name: "footerPlayer",
  components: {
    animationSelection
  },
  props: {
    canPlay: Boolean,
    playing: Boolean,
    loading: Boolean,
    mobile: Boolean,
    config : Object,
  },
  data: () => {
    return {
      volume: 0.5,
      errors: {},
      timeStart: 0,
      timeDisplay: '00:00:00',
      timeItv: null,
      TitreEnCours: "",
      ArtisteEnCours: "",
    }
  },
  watch : {
    volume() {
      _audio.setVolume(this.volume);
    },
    // watch playing status
    playing() {
      if (this.playing) {
        this.startClock();
      } else {
        this.stopClock();
      }
    },
  },

  computed: {
    currentSong(){
      return this.$store.getters["nowplaying/courrentSong"];
    },
    //config(){
    //  return this.$store.getters["playerConfig/getConfig"];
    //},
    hasSongs: (songs) => {
      return (!!Array(songs).length);
    }
  },

 created() {
    this.loadDedicaces();
    // Ajoutez un délai pour afficher chaque dédicace
    setInterval(this.showNextDedicace, 9500); // Changez le délai selon vos besoins
  },
  methods : {
    
  loadDedicaces() {
    axios.get("https://api.danceradio.fr/get_dedicaces.php")
    .then(response => {
      console.log(response.data);
          // Mettez à jour messages avec les données reçues
          this.dedicaces = Object.values(response.data);
   
    })
},
submitTopVote(topvote){
            axios.post('https://api.danceradio.fr/post_topvote.php?choice='+topvote)
                 .then((res) => {
                     console.log(res);

                        this.dedicace = false;
                        this.notification.message = res.data.message;
                        this.notification.code = res.data.code;
                     
                     setTimeout(function() {
                      this.notification.message = '';
                      this.notification.code = '';
                      this.dedicace = true;
                    }.bind(this), 4000);
                 })
                 .catch((error) => {
                  console.log(error);
                     // error.response.status Check status code
                 }).finally(() => {
                     //Perform action in always
                 });
        },
    showNextDedicace() {
      this.currentDedicaceIndex = (this.currentDedicaceIndex + 1) % this.dedicaces.length;
    },
    togglePlay() {
      this.$emit('togglePlay')
    },
    volumeUp(){
      if (this.volume + 0.1 > 1) return;
      this.volume += 0.1
    },
    volumeDown(){
      if (this.volume - 0.1 < 0) return;
      this.volume -= 0.1
    },
    // start tracking playback time
    startClock() {
      this.stopClock();
      this.timeStart = Date.now();
      this.timeItv = setInterval(this.updateClock, 1000);
      this.updateClock();
    },

    // update tracking playback time
    updateClock() {
      let p = n => (n < 10) ? '0' + n : '' + n;
      let elapsed = (Date.now() - this.timeStart) / 1000;
      let seconds = Math.floor(elapsed % 60);
      let minutes = Math.floor(elapsed / 60 % 60);
      let hours = Math.floor(elapsed / 3600);
      this.timeDisplay = p(hours) + ':' + p(minutes) + ':' + p(seconds);
    },

    // stop tracking playback time
    stopClock() {
      if (this.timeItv) clearInterval(this.timeItv);
      this.timeItv = null;
    },

    // clear timer refs
    clearTimers() {
      if (this.sto) clearTimeout(this.sto);
      if (this.itv) clearInterval(this.itv);
      //if (this.anf) cancelAnimationFrame(this.anf);
    },


  },
  destroyed() {
    this.clearTimers();

  }

}
</script>

<style scoped>

</style>