<template>
    <div id="app" class="app-wrap" >
        <!-- app player container -->
        <main id="player-wrap" class="player-wrap" >
            <!-- bg absolute elements -->
            <figure v-if="PochetteEnCours" id="player-bg" class="player-bg" v-bind:style="{ 'background-image' : `url(  ${ this.currentSong.pochette} )` }"></figure>
            <figure v-else id="player-bg" class="player-bg" v-bind:style="{ 'background-image' : `url(  img/icon.png )` }"></figure>
            <div class="layout--video" style="    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;" v-if="!mobile" v-show="youtubePlayer.show">
            <div style="    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 2;
    transform: scale(1.65);" id="player"></div>
        </div>
            <!-- main player layout -->
            <section class="player-layout" v-if="init">
                <!-- player top header -->
                <header class="player-header flex-row flex-middle flex-stretch" v-if="mobile == true">
                    <div class="text-clip flex-5">
                      <router-link to="/" custom v-slot="{ navigate }" >
                        <img :src="config.logo" @click="navigate" @keypress.enter="navigate" width="65px">
                        <!-- <span @click="navigate" @keypress.enter="navigate" role="link"> {{ config.title }}</span>-->
                      </router-link>
                    </div>
                    <!--<h2 class="text-clip flex-1" style="text-align:center;font-size: 0.95rem;color: white;text-transform: uppercase;">{{this.pageTitle}}</h2>-->
                    <button class="text-nowrap common-btn flex-1" id="sidebar" @click="toggleSidebar( true )"><i
                            class="fa fa-bars" aria-label="sidebar"></i></button>
                </header>
                <header class="player-header flex-item flex-1  flex-middle flex-stretch" v-else>

                  <nav class="navbar">
    <div class="navbar-brand">
      <router-link class="navbar-item" to="/" custom v-slot="{ navigate }" ><img @click="navigate" @keypress.enter="navigate" width="90px" :src="config.logo"></router-link>
      <a role="button" class="navbar-burger" :class="{ 'is-active': isActive }" @click="toggleNavbar" aria-label="menu" aria-expanded="false">
        <i data-v-329176b0="" class="fa-solid fas fa-hamburger fx fx-drop-in"></i>
      </a>
    </div>

    <div class="navbar-center" :class="{ 'is-active': isActive }">
      <router-link class="navbar-item" tag="a" to="/">Accueil</router-link>
    <router-link class="navbar-item" tag="a" to="/derniers-titres">Derniers titres</router-link>
    <router-link class="navbar-item" tag="a" to="/topvote">TopVote</router-link>
    <router-link class="navbar-item" tag="a" to="/emissions">Programme</router-link>
    <!--<router-link class="menu" tag="li" to="/equipe"><a>L'équipe</a></router-link>-->
    <router-link class="navbar-item" tag="a" to="/dedicace">Dedicace</router-link>
    <router-link class="navbar-item" tag="a" to="/partenaire">Partenaire</router-link>
    <router-link class="navbar-item" tag="a" to="/contact">Contact</router-link>
    </div>
  </nav>
<!--
                  <div class="top-bar">
<ul data-turbolinks="true">
    <li class="menu logo"><router-link to="/" custom v-slot="{ navigate }" ><img @click="navigate" @keypress.enter="navigate" width="90px" :src="config.logo"></router-link></li> 
    <router-link class="menu" tag="li" to="/"><a>Accueil</a></router-link>
    <router-link class="menu" tag="li" to="/derniers-titres"><a>Derniers titres</a></router-link>
    <router-link class="menu" tag="li" to="/topvote"><a>TopVote</a></router-link>
    <router-link class="menu" tag="li" to="/emissions"><a>Programme</a></router-link>
    <router-link class="menu" tag="li" to="/equipe"><a>L'équipe</a></router-link>
    <router-link class="menu" tag="li" to="/dedicace"><a>Dedicace</a></router-link>
    <router-link class="menu" tag="li" to="/partenaire"><a>Partenaire</a></router-link>
    <router-link class="menu" tag="li" to="/contact"><a>Contact</a></router-link>
  
  
  
</ul>
</div>
-->
<div class="bottom-bar"><div class="player-header-content">
  
  <section class="player-links flex-row flex-left dedicaces">
<i v-if="dedicaces.length && dedicace"class="fa-regular fa-comments fx fx-drop-in pad-right"></i>
    <transition-group name="transition" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
        <div v-if="dedicaces.length && dedicace" :key="dedicaces[currentDedicaceIndex].id" class="pad-left">
            <span>{{ dedicaces[currentDedicaceIndex].posteur }} - {{ dedicaces[currentDedicaceIndex].dedicace }}</span>
        </div>
    </transition-group>
         <i v-if="notification.code == '0'"class="fa-solid fa-exclamation fx fx-drop-in pad-right"></i>
         <i v-if="notification.code == '1'"class="fa-solid fa-check fx fx-drop-in pad-right"></i>
        <div v-if="notification.message" class="pad-left">
            <span v-if="notification.code == '0'" style="color:red">{{ notification.message }}</span>
            <span v-if="notification.code == '1'" style="color:green">{{ notification.message }}</span>
        </div>
  </section>

</div></div>
                </header>

                 <router-view> </router-view>

                 <footer-player :loading="loading" :playing="playing"
                   v-on:togglePlay="togglePlay" :config="config" :mobile="mobile"></footer-player>

                   <div class="social">
                    <a class="card push-bottom fx fx-slide-up fx-delay-3" href="https://www.facebook.com/Enkaradio" target="_blank">
                    <i class="fa-brands fa-facebook-f"></i>
                    </a> 
                    <a class="card push-bottom fx fx-slide-up fx-delay-3" href="https://twitter.com/enkaradio" target="_blank">
                    <i class="fa-brands fa-x-twitter"></i>
                    </a> 
                    <a class="card push-bottom fx fx-slide-up fx-delay-3" href="https://enkaradio.be/enka_radio" target="_blank">
                    <i class="fa-brands fa-instagram"></i>
                    </a> 
                    </div>

            </section> <!-- layout wrapper -->
            <!-- player stations overlay + sidebar -->
            <section class="player-stations"
                     :class="{ 'visible': sidebar }"
                     @click="toggleSidebar( false )"
            >
                <aside class="player-stations-sidebar" @click.stop>
                    <!-- sidebar search -->
                    <header class="player-stations-header flex-row flex-middle flex-stretch">
                        <div class="form-input push-right">
                        </div>
                        <button class="common-btn" @click="toggleSidebar( false )"><i
                                class="fa fa-times-circle" aria-label="Closesidebar"></i>
                        </button>
                    </header>
<br>

                    <!-- sidebar stations list -->
                    <ul class="player-stations-list">
                        <router-link tag="li" to="/" custom v-slot="{ navigate }" class="player-stations-list-item flex-row flex-top flex-stretch" >
                            <span @click="navigate" @keypress.enter="navigate" role="link"> Accueil</span>
                        </router-link>

                        <router-link tag="li" to="/derniers-titres" custom v-slot="{ navigate }" class="player-stations-list-item flex-row flex-top flex-stretch" >
                            <span @click="navigate" @keypress.enter="navigate" role="link"> Derniers Titres</span>
                        </router-link>

                        <router-link tag="li" to="/topvote" custom v-slot="{ navigate }" class="player-stations-list-item flex-row flex-top flex-stretch" >
                            <span @click="navigate" @keypress.enter="navigate" role="link"> TopVote</span>
                        </router-link>

                        <router-link tag="li" to="/emissions" custom v-slot="{ navigate }" class="player-stations-list-item flex-row flex-top flex-stretch" >
                            <span @click="navigate" @keypress.enter="navigate" role="link"> Programmes</span>
                        </router-link>

                        <!--<router-link tag="li" to="/equipe" custom v-slot="{ navigate }" class="player-stations-list-item flex-row flex-top flex-stretch" >
                            <span @click="navigate" @keypress.enter="navigate" role="link"> L'équipe</span>
                        </router-link>-->

                        <router-link tag="li" to="/dedicace" custom v-slot="{ navigate }" class="player-stations-list-item flex-row flex-top flex-stretch" >
                            <span @click="navigate" @keypress.enter="navigate" role="link"> Dédicace</span>
                        </router-link>

                        <router-link tag="li" to="/partenaire" custom v-slot="{ navigate }" class="player-stations-list-item flex-row flex-top flex-stretch" >
                            <span @click="navigate" @keypress.enter="navigate" role="link"> Partenaire</span>
                        </router-link>

                        <router-link tag="li" to="/contact" custom v-slot="{ navigate }" class="player-stations-list-item flex-row flex-top flex-stretch" >
                            <span @click="navigate" @keypress.enter="navigate" role="link"> Contact</span>
                        </router-link>
                    </ul>

                    <!--<<router-link to="/" custom v-slot="{ navigate }" >
                        <span @click="navigate" @keypress.enter="navigate" role="link"> Accueil</span>
                      </router-link><br>

                      <router-link to="/station" custom v-slot="{ navigate }" >
                        <span @click="navigate" @keypress.enter="navigate" role="link"> test</span>
                      </router-link>

                      <router-link to="/dedicace" custom v-slot="{ navigate }" >
                        <span @click="navigate" @keypress.enter="navigate" role="link"> Dedicaces</span>
                      </router-link>

                      <router-link to="/derniers-titres" custom v-slot="{ navigate }" >
                        <span @click="navigate" @keypress.enter="navigate" role="link"> Derniers Titres</span>
                      </router-link><br>

                      <router-link to="/share" custom v-slot="{ navigate }" >
                        <span @click="navigate" @keypress.enter="navigate" role="link"> Partager</span>
                      </router-link><br>-->

                    
                </aside>
            </section>

        </main> <!-- player -->

            
    </div>
</template>

<script>
import axios from 'axios';
    import _audio from './js/audio';
    import './scss/app.scss';
    import './js/filters';

    import { mapGetters, mapState  } from 'vuex';
    import { debounce } from "debounce";
    import footerPlayer from '@/views/station/components/footerPlayer'
    import { mutations as nowplayingMutations } from './store/nowplaying';
    import update from './mixins/update'
    export default {
      mutations: {
    ...nowplayingMutations
  },
        name: 'home',
        components: {
    footerPlayer,
  },
        data: () => {
            return {
                // toggles
                isActive: false,
                init: false,
                visible: false,
                loading: true,
                sidebar: false,
                errors: {},
                playing: false,
                volume: 1,
                ArtisteEnCours: "",
                TitreEnCours: "",
                PochetteEnCours : "/img/icon.png",
                pageTitle: "",
                mobile: false,
                youtubePlayer: {
                  player: null,
                  show: false,
                  video_id: ""
                },
                dedicaces: [],
      currentDedicaceIndex: 0,
      dedicace: true,
      notification: {
        code: null,
        message: null
      }
            }
        },
        mixins: [update],
          // watch methods
  watch: {
    $route() {
                this.PochetteEnCours = this.PochetteEnCours;
                this.toggleSidebar();
            },
            $route(to, from) {
      // Met à jour le titre de la page lorsqu'il y a un changement de route
      this.pageTitle = to.meta.title; // Utilise le titre de la méta-information de route, sinon un titre par défaut
      
    },
  },
        methods: {
          toggleNavbar() {
      this.isActive = !this.isActive;
    },
          loadDedicaces() {
    axios.get("https://ythomas.fr/enkaradio/api/get_dedicaces.php")
    .then(response => {
      console.log(response.data);
          // Mettez à jour messages avec les données reçues
          this.dedicaces = Object.values(response.data);
   
    })
},
showNextDedicace() {
      this.currentDedicaceIndex = (this.currentDedicaceIndex + 1) % this.dedicaces.length;
    },
  test() {
            this.$store.dispatch("nowplaying/fetchNowplaying");
            setTimeout(this.test, 12000); 
            if (this.currentSong.idvideo !== this.youtubePlayer.video_id) {
            this.youtubePlayer.video_id  = this.currentSong.idvideo;
                if (this.youtubePlayer.video_id !== false) {
        if (!this.mobile) this.initVideo(this.youtubePlayer.video_id );
      }
    }
            
},

    updateMediaSession(Artiste, Titre, Pochette) {
      console.log("%c updateMediaSession", 'color: green', Artiste)
      if ('mediaSession' in navigator && !!Artiste) {
        navigator.mediaSession.metadata = new MediaMetadata({
          title: Artiste,
          artist: Titre,
          artwork: [
            {src: Pochette}
          ]
        });
      }
    },
            initView() {
                console.log("init View");

                this.init = true;
                document.querySelector('#_spnr').style.display = 'none';
                console.log("init View 2");
                document.querySelector('#player-wrap').style.opacity = '1';
                document.querySelector('#player-wrap').style.display = 'block';
                console.log("init View 3");
                document.addEventListener('visibilitychange', e => {
                    this.visible = (document.visibilityState === 'visible')
                });
                console.log("init View 4");


            },
            setError(key, err) {
                let errors = Object.assign({}, this.errors);
                errors[key] = String(err || '').trim();
                if (err) console.warn('ERROR(' + key + '):', err);
                this.errors = errors;
            },
            toggleSidebar(toggle) {
                this.sidebar = (typeof toggle === 'boolean') ? toggle : false;
            },
            onKeyboard(e) {
                const k = e.key || '';
                if (k === 'Enter') return this.toggleSidebar(true);
                if (k === 'Escape') return this.toggleSidebar(false);
            },
                clearErrors() {
      Object.keys(this.errors).forEach(key => {
        this.errors[key] = '';
      });
    },

            initPlayer() {
      window.addEventListener('keydown', this.onKeyboard);
      this.visible = true;
      //document.title = this.station.name +" - "+ this.config.title
    },

    // reset selected channel
    resetPlayer() {
      this.visible = false
      this.closeAudio();
      this.clearErrors();
    },


    // try resuming stream problem if possible
    tryAgain() {
      this.clearErrors();
      this.playChannel();
    },
    // toggle stream playback for current selected channel
    togglePlay() {
      if (this.loading) return;
      if (this.playing) return this.closeAudio();
      return this.playChannel();
    },

    // setup audio routing and stream events
    setupAudio() {
      const a = _audio.setupAudio();

      a.addEventListener('waiting', e => {
        this.playing = false;
        this.loading = true;
      });
      a.addEventListener('playing', e => {
        this.setError('stream', '');
        this.playing = true;
        this.loading = false;
      });
      a.addEventListener('ended', e => {
        this.playing = false;
        this.loading = false;
      });
      a.addEventListener('error', e => {
        this.closeAudio();
        console.log(e);
        this.setError('stream', `The selected stream () could not load, or has stopped loading due to a network problem.`);
        console.log('Error AUDIPOOOOOOOOOOOOOOOOOOO');
        this.playing = false;
        this.loading = false;
      });
    },

   // close active audio
    closeAudio() {
      _audio.stopAudio();
      this.playing = false;
    },

    // play audio stream for a channel
    playChannel() {

      this.loading = false;
      this.clearErrors();
     
    console.log("Je joue le son")
      //if(!!this.station)
      _audio.playSource(this.config.URL_Audio);
      console.log("Je joue le son")
      _audio.setVolume(this.volume);
    },


    Play() {
      this.closeAudio();
      this.initPlayer();
      this.playChannel();
    },

    
    // on keyboard events
    onKeyboard(e) {
      const k = e.key || '';
      if (k === ' ') return this.togglePlay();
    },

initVideo: function initVideo(data) {
      var _this = this;

      if (!this.youtubePlayer.player) {
        var tag = document.createElement('script');
        tag.src = "//www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubeIframeAPIReady = function () {
          // console.log('youtube start at', (start + data.video_start_at));
          _this.youtubePlayer.player = new YT.Player('player', {
            videoId: data,
            suggestedQuality: 'large',
            playerVars: {
              autoplay: 1,
              mute: 1,
              'controls': 0,
              'iv_load_policy': 3,
              'modestbranding': 1,
              'rel': 0,
              'showinfo': 0,
              'cc_load_policy': 0,
              'disablekb': 1,
              'enablejsapi': 1,
              'fs': 0
            },
            events: {
              'onReady': _this.youtubeOnPlayerReady,
              'onStateChange': _this.youtubeOnPlayerStateChange,
              'onError': _this.youtubeOnPlayerError
            }
          });
        };
      } else {
        this.youtubePlayer.player.loadVideoById({
          videoId: data,
          suggestedQuality: 'large'
        });
      }
    },

    youtubeOnPlayerReady: function youtubeOnPlayerReady(event) {
      event.target.playVideo();
    },
    youtubeOnPlayerStateChange: function youtubeOnPlayerStateChange(event) {
      if (event.data == YT.PlayerState.PLAYING) {
        this.youtubePlayer.show = true;
      } else if (event.data == YT.PlayerState.ENDED || event.data == YT.PlayerState.PAUSED || event.data == YT.PlayerState.BUFFERING || event.data == YT.PlayerState.CUED) {
        this.youtubePlayer.show = false;
      }
    },
    youtubeOnPlayerError: function youtubeOnPlayerError(event) {
      this.youtubePlayer.show = false;
    },

},

        computed: {
            config(){
              return this.$store.getters["playerConfig/getConfig"];
            },
            currentSong(){
      return this.$store.getters["nowplaying/courrentSong"];
    },

        },
        // on app mounted
        mounted() {
            console.log("App : mounted");
            this.$store.dispatch('playerConfig/fetchConfig').then(()=>{
              document.title = this.config.title;
              //this.initView();
            });
            this.$store.dispatch('nowplaying/fetchNowplaying').then(()=>{
                this.initView();
            });
                this.setupAudio();
    this.Play();
    this.test();

    var _this2 = this;
    if (_this2.youtubePlayer.video_id !== false) {
        _this2.initVideo(_this2.youtubePlayer.video_id , 0);
        console.log('Jai reussi');
      }



        },

        // on app destroyed
        destroyed() {

        },

        created() {
          
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
        },

    beforeDestroy() {
    this.closeAudio();
  },

  created() {

    this.loadDedicaces();
    // Ajoutez un délai pour afficher chaque dédicace
    setInterval(this.showNextDedicace, 9500); // Changez le délai selon vos besoins
  },



    }

</script>

