import Vue from 'vue'
import Router from 'vue-router'

const Station = () => import('./views/station/Station')
const Home =  () => import('./views/Home')
const Dedicace =  () => import('./views/Dedicace')
const Share =  () => import('./views/Share')
const Derniers_titres =  () => import('./views/station/components/songsHistory')
const Emissions =  () => import('./views/Emissions')
const TopVote =  () => import('./views/TopVote')
const Partenaire =  () => import('./views/Partenaire')
const Contact =  () => import('./views/Contact')
const Home2 =  () => import('./views/Home_officiel')
const Equipe =  () => import('./views/Equipe')
const PodcastDetails =  () => import('./views/Podcast')

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: "active",
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home2
    },
    {
      path: '/station',
      name: 'station',
      meta: { title: "Accueil" },
      component: Station
    },
    {
      path: '/dedicace',
      name: 'dedicace',
      meta: { title: "Poste une dédicace" },
      component: Dedicace
    },
    {
      path: '/share',
      name: 'share',
      meta: { title: "Partager" },
      component: Share
    },
    {
      path: '/derniers-titres',
      name: 'derniers_titres',
      meta: { title: "Derniers titres" },
      component: Derniers_titres
    },
    {
      path: '/emissions',
      name: 'emissions',
      meta: { title: "Programmes" },
      component: Emissions
    },
    {
      path: '/topvote',
      name: 'topvote',
      meta: { title: "topvote" },
      component: TopVote
    },
    {
      path: '/partenaire',
      name: 'partenaire',
      meta: { title: "partenaire" },
      component: Partenaire
    },
    {
      path: '/contact',
      name: 'contact',
      meta: { title: "contact" },
      component: Contact
    },
    {
      path: '/equipe',
      name: 'equipe',
      meta: { title: "equipe" },
      component: Equipe
    },
    {
      path: '/podcast/:id',
      name: 'PodcastDetails',
      component: PodcastDetails,
      props: true
    }
  ]
})
